import type { GetServerSideProps } from 'next';

import { initializeApollo } from '@/shared/libs/apollo';
import ME_QUERY from '@/views/Auth/graphql/AuthPagesMeQuery.graphql';
import { AuthPagesMeQuery } from '@/views/Auth/graphql/types/AuthPagesMeQuery';
import routes from '@/views/Auth/routes';

const getServerSideUserAuth =
  ({
    pageKey,
    authenticated = true,
  }: {
    pageKey: string;
    authenticated?: boolean;
  }): GetServerSideProps =>
  async ({ req }) => {
    const apolloClient = initializeApollo({
      pageKey,
      headers: req.headers,
    });

    const { data } = await apolloClient.query<AuthPagesMeQuery>({
      query: ME_QUERY,
      context: { headers: { cookie: req.headers.cookie } },
    });

    const firstCase = data.me && !authenticated;
    const secondCase = !data.me && authenticated;

    if (firstCase || secondCase) {
      let destination = secondCase ? routes.loginPath : routes.homeUser;

      if (data?.me?.hasOrganization && data?.me?.organization?.slug) {
        destination = secondCase
          ? routes.b2bOrgLoginPath(data.me.organization.slug)
          : routes.b2bOrgHomeUser(data.me.organization.slug);
      }

      return {
        redirect: {
          destination,
          permanent: false,
        },
      };
    }

    return {
      props: {
        me: data.me,
      },
    };
  };

export default getServerSideUserAuth;
