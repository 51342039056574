import { IncomingHttpHeaders } from 'http';

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';

import {
  I18nTranslation,
  transformInitialStateTranslations,
} from '@crehana/i18n';
import { getG11nDataFromHeaders } from '@crehana/next';
import { LANGUAGES, RequiredAndNotNull } from '@crehana/ts-types';
import { notEmpty } from '@crehana/utils';

import TRANSLATION_KEYS_QUERY from './graphql/TranslationKeysQuery.graphql';
import {
  GetTranslations,
  GetTranslationsVariables,
  GetTranslations_translationKeys_keys as Keys,
} from './graphql/types/GetTranslations';

type GetI18nConfig = {
  language: LANGUAGES;
  namespaces: string[];
  /** @default true */
  includeMainMenu?: boolean;
  /** @default true */
  includeMainFooter?: boolean;
};

async function getI18n(
  client: ApolloClient<NormalizedCacheObject>,
  config: GetI18nConfig,
): Promise<I18nTranslation | undefined> {
  const defaultConfig: GetI18nConfig = {
    includeMainMenu: true,
    includeMainFooter: true,
    ...config,
  };
  const pages: string[] = defaultConfig.namespaces;

  if (defaultConfig.includeMainMenu) {
    pages.push('main-menu');
  }
  if (defaultConfig.includeMainFooter) {
    pages.push('main-footer');
  }

  try {
    const { data } = await client.query<
      GetTranslations,
      GetTranslationsVariables
    >({
      query: TRANSLATION_KEYS_QUERY,
      variables: {
        language: config.language || LANGUAGES.ES,
        pages: pages.join(','),
      },
    });
    const translations =
      data.translationKeys?.filter(notEmpty).map(t => ({
        language: t.language!,
        page: t.page!,
        keys: t.keys! as RequiredAndNotNull<Keys>[],
      })) || [];

    return transformInitialStateTranslations(translations);
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        query: 'GetTranslations',
        namespaces: config.namespaces,
      },
    });
  }
}

type GetI18nDataOptions = {
  // req.headers
  headers: IncomingHttpHeaders;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  pageKey: string;
  namespaces?: string[];
  includeMainMenu?: boolean;
  includeMainFooter?: boolean;
  defaultTranslations: I18nTranslation;
};

export const getI18nData = async ({
  headers,
  apolloClient,
  pageKey,
  namespaces,
  defaultTranslations,
  includeMainMenu = false,
  includeMainFooter = false,
}: GetI18nDataOptions) => {
  const { languageCode: language, countryCode: countryPrefix } =
    getG11nDataFromHeaders(headers);

  const translations = await getI18n(apolloClient, {
    language,
    namespaces: namespaces || [pageKey],
    includeMainMenu,
    includeMainFooter,
  });

  return {
    i18nProviderProps: {
      languageCode: language,
      defaultNs: pageKey,
      translations,
      defaultTranslations,
      countryPrefix,
      countryCode: countryPrefix,
    },
    countryPrefix,
    language,
  };
};

export default getI18n;
