import type { GetServerSideProps } from 'next';

import type { Locales } from '@crehana/i18n';

import { initializeApollo } from '@/shared/libs/apollo';
import { getI18nData } from '@/shared/utils/getI18n/getI18n';

const getServerSideI18nProps =
  ({
    pageKey,
    translations,
  }: {
    pageKey: string;
    translations: Record<string, Locales>;
  }): GetServerSideProps =>
  async ({ req }) => {
    const apolloClient = initializeApollo({
      headers: req.headers,
      pageKey,
    });

    const i18nData = await getI18nData({
      apolloClient,
      headers: req.headers,
      pageKey,
      defaultTranslations: {
        es: { [pageKey]: translations.es },
        pt: { [pageKey]: translations.pt },
        en: { [pageKey]: translations.en },
      },
    });

    return {
      props: {
        initializeApollo: apolloClient.cache.extract(),
        ...i18nData,
      },
    };
  };

export default getServerSideI18nProps;
