import { GetServerSideProps } from 'next';

import { initializeApollo } from '@/shared/libs/apollo';
import AUTH_PAGES_ORG_INFO_QUERY from '@/views/Auth/graphql/AuthPagesOrgInfoQuery.b2b.graphql';
import {
  AuthPagesOrgInfoQuery,
  AuthPagesOrgInfoQueryVariables,
  AuthPagesOrgInfoQuery_organizationInfo as OrgInfoType,
} from '@/views/Auth/graphql/types/AuthPagesOrgInfoQuery';
import { B2BOrgParams } from '@/views/Auth/types';

const getServerSideOrgInfo: GetServerSideProps<
  { orgInfo: OrgInfoType },
  B2BOrgParams
> = async ({ req, params }) => {
  const apolloClient = initializeApollo({
    headers: req.headers,
  });

  const { data } = await apolloClient.query<
    AuthPagesOrgInfoQuery,
    AuthPagesOrgInfoQueryVariables
  >({
    query: AUTH_PAGES_ORG_INFO_QUERY,
    context: { clientName: 'b2b' },
    variables: {
      slug: params?.slug as string,
    },
  });

  if (!data?.organizationInfo) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      orgInfo: data.organizationInfo,
    },
  };
};

export default getServerSideOrgInfo;
