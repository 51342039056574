import { ParsedUrlQuery } from 'querystring';

import { merge } from 'lodash';
import {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetServerSidePropsResult,
} from 'next';

interface KeyAny {
  [key: string]: any;
}

const getServerSidePropsCompose =
  <
    P extends KeyAny = KeyAny,
    Q extends ParsedUrlQuery = ParsedUrlQuery,
    FP extends KeyAny = KeyAny,
  >(
    ...functions: GetServerSideProps<FP, Q>[]
  ) =>
  async (
    context: GetServerSidePropsContext<Q>,
  ): Promise<GetServerSidePropsResult<P>> => {
    let data = {};

    for await (const func of functions) {
      const response = await func(context);

      // @ts-ignore
      if (response.redirect || response.notFound) {
        data = response;
        break;
      }

      data = merge(data, response);
    }

    // @ts-ignore
    return data;
  };

export default getServerSidePropsCompose;
