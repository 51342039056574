import { parseQueryStrings } from '@crehana/utils';

export const getNextUrl = (): string => {
  const { next, next_url } =
    parseQueryStrings(
      typeof window !== 'undefined' ? window.location.search : '',
    ) || {};

  return typeof next === 'string'
    ? next
    : typeof next_url === 'string'
    ? next_url
    : '';
};

export default getNextUrl;
