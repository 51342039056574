import qs from 'qs';

/**
 *
 * Keep in mind that this will return undefined in the server
 */
var getQueryParam = function getQueryParam(key) {
  if (typeof window === 'undefined') return '';
  var search = window.location.search.substring(1);
  if (!search.length) {
    return null;
  }
  return qs.parse(search)[key];
};
export default getQueryParam;